$(function () {
  $('.rslides').responsiveSlides({
    auto: true,
    speed: 750,
    timeout: 4000,
    nav: true,
    pager: true,
    pause: true,
    // namespace: "large-btns"
    namespace: "centered-btns"
  });
});